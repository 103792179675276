<template>
  <div
    class="flex flex-col items-center justify-center space-y-2 px-4 py-8"
  >
    <p class="text-lg">
      Iframe Block
    </p>
    <p v-if="content.url" class="text-sm text-gray-700">
      Embed will show {{ content.url }}
    </p>
    <p v-else>
      Embed has no URL assigned to it yet.
    </p>
  </div>
</template>
<script>
export default {
  friendlyName: 'IFrame Block',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content
    }
  }
}
</script>
